import useUserSelector from '@src/store/selectors/useUserSelector';

import {
  pageHotmartFortnite,
  pageHotmartLol,
  pageHotmartValorant,
} from '../resources/config';
import useCurrentGame from './localStorage/useCurrentGame';

export default () => {
  const { isValorant, isFortnite } = useCurrentGame();
  const { user } = useUserSelector();

  const redirectToHotmart = () => {
    const userQueryString = user ? `&email=${user.profile.email}` : '';

    if (isValorant) {
      window.open(`${pageHotmartValorant}${userQueryString}`, '_blank');
    }

    if (isFortnite) {
      window.open(`${pageHotmartFortnite}${userQueryString}`, '_blank');
    }

    window.open(`${pageHotmartLol}${userQueryString}`, '_blank');
  };

  return { redirectToHotmart };
};
