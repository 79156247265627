import { version } from '../../../package.json';
import { gamesEnum } from '../../utils/games.utils';

export default () => {
  const izziVersion = `izzi-gaming-${version}`;
  const currentGame = localStorage.getItem(`${izziVersion}-current-game`);
  const isLol = currentGame === gamesEnum.lol;
  const isValorant = currentGame === gamesEnum.valorant;
  const isFortnite = currentGame === gamesEnum.fortnite;

  const setCurrentGame = (gameName) =>
    localStorage.setItem(`${izziVersion}-current-game`, gameName);

  return { isLol, isValorant, isFortnite, currentGame, setCurrentGame };
};
