import React, { lazy } from 'react';

const ForgotPassword = lazy(() => import('../screens/ForgotPassword'));
const ResetPassword = lazy(() => import('../screens/ResetPassword'));

const RegisterForm = lazy(() =>
  import('../screens/authentication/RegisterForm')
);
const SignIn = lazy(() => import('../screens/authentication/SignIn.screen'));
const SignUp = lazy(() => import('../screens/authentication/SignUp.screen'));

const addCommonProps = (route) => ({
  ...route,
  exact: true,
});

const authRoutes = [
  { path: '/signin/:game', element: () => <SignIn /> },
  { path: '/signin', element: () => <SignIn /> },
  { path: '/signup', element: () => <SignUp /> },
  { path: '/signup/:game', element: () => <SignUp /> },
  { path: '/forgot-password', element: () => <ForgotPassword /> },
  { path: '/reset-password/:token', element: () => <ResetPassword /> },
  { path: '/gaming', element: () => <RegisterForm /> },
  { path: '/gaming/:gameName', element: () => <RegisterForm /> },
];

const mappedAuthRoutes = authRoutes.map(addCommonProps);

export default mappedAuthRoutes;
