import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useCurrentGame from '@hooks/localStorage/useCurrentGame';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { gamesEnum } from '@utils/games.utils';

import { version } from '../../../../../package.json';
import IconLol from '../../../../assets/icons/Lol.svg';
import {
  Container,
  ContainerDrop,
  IconExpandLess,
  IconExpandMore,
  IconGame,
  Menu,
  OptionMenu,
  Title,
  TitleGameMenu,
} from './style';

const SelectGame = ({ listGames, userId }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const izziVersion = `izzi-gaming-${version}`;
  const { currentGame } = useCurrentGame();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const getGameImg = (game) =>
    listGames.filter((g) => g.codeName === game)[0]?.logo ?? IconLol;

  const getGameName = (game) =>
    listGames.filter((g) => g.codeName === game)[0]?.name ??
    'League of Legends';

  const settingGameOnStorage = (game) => {
    setOpen(false);

    if (currentGame !== game) {
      localStorage.setItem(`${izziVersion}-current-game`, game);
      if (pathname.includes('classes')) {
        navigate('/classes');
        if (pathname === '/classes') {
          window.location.reload();
        }
      } else if (pathname.includes('/library/')) {
        navigate('/library');
      } else if (pathname.includes('izzi-build') && game !== gamesEnum.lol) {
        navigate('/home');
      } else if (pathname.includes('analysis')) {
        navigate(`/analysis/${game}/${userId}`);
        if (pathname === `/analysis/${game}/${userId}`) {
          window.location.reload();
        }
      } else {
        window.location.reload();
      }
    }
  };

  const handleClickOut = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (currentGame === gamesEnum.valorant && pathname.includes('coaches')) {
      navigate('/home');
    }
  }, [currentGame, pathname]);

  const gameMenuItems = listGames.map((e) => (
    <OptionMenu onClick={() => settingGameOnStorage(e.codeName)} key={e.id}>
      <IconGame src={e.logo} alt="Game icon" />
      <TitleGameMenu>{e.name}</TitleGameMenu>
    </OptionMenu>
  ));

  return (
    <Box>
      <Container
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClickOut}>
                <Menu>{gameMenuItems}</Menu>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Container>

      <Grid container justifyContent="center">
        <Button onClick={handleClick('bottom')}>
          <ContainerDrop>
            <img src={getGameImg(currentGame)} alt="Game icon" />
            <Title>{getGameName(currentGame)}</Title>

            {open ? <IconExpandLess /> : <IconExpandMore />}
          </ContainerDrop>
        </Button>
      </Grid>
    </Box>
  );
};
export default SelectGame;
