import content from './content.state';
import diagnostic from './diagnostic.state';
import track from './track.state';
import ui from './ui.state';

const initialState = {
  content,
  track,
  diagnostic,
  ui,
};

export default initialState;
