import axios from 'axios';

import { version } from '../../package.json';
import setToast from '../utils/toast.utils';
import { izzi, vendors } from './config';

const izziVersion = `izzi-gaming-${version}`;

const requester = async (
  method,
  endpoint,
  data = null,
  vendor = false,
  params = null
) => {
  const baseURL = vendor ? vendors[vendor].url : izzi;
  const config = {
    method,
    baseURL,
    url: endpoint,
    data,
    params,
  };
  return axios
    .request(config)
    .then((response) => [null, response.data])
    .catch((error) => {
      if (error?.response?.status === 401) {
        setToast('error', 'Token Expirado');
        window.location.replace('/signin');
        localStorage.clear();
      }
      return [error];
    });
};

export const requesterFormData = async (
  method,
  endpoint,
  data = null,
  vendor = false
) => {
  const baseURL = vendor ? vendors[vendor].url : izzi;
  const token = localStorage.getItem(`${izziVersion}-token`);
  const config = {
    method,
    baseURL,
    url: endpoint,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .request(config)
    .then((response) => [null, response.data])
    .catch((error) => {
      if (error?.response?.status === 401) {
        setToast('error', 'Token Expirado');
        window.location.replace('/signin');
      }
      return [error];
    });
};

axios.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem(`${izziVersion}-token`);
    if (config?.baseURL?.includes('vimeo')) {
      token = vendors.vimeo.token;
    }

    const url = new URL(window.location.href);
    const urlToken = url.searchParams.get('token');
    if (!token && urlToken) {
      token = urlToken;
    }

    const isAuthRoute =
      config?.url?.includes('auth') &&
      !config?.url?.includes('resignin') &&
      !config?.url?.includes('update-password');

    const configToReturn =
      (token && config?.baseURL?.includes('vimeo')) ||
      (config.baseURL === izzi &&
        !(isAuthRoute || config.url === '/admin/users'))
        ? { ...config, headers: { Authorization: `Bearer ${token}` } }
        : config;

    return configToReturn;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // eslint-disable-next-line no-console
    if (error.response.status === 401) console.error(error);
    return Promise.reject(error);
  }
);

export default requester;
