import useCurrentGame from '@hooks/localStorage/useCurrentGame';

import useGlobal from '..';

export default () => {
  const [store] = useGlobal();
  const { currentGame } = useCurrentGame();

  const { user } = store;

  const activeSubscriptions =
    user?.subscription?.filter(
      (s) => s.status === 'ACTIVE' && new Date() <= new Date(s.expiresIn)
    ) || [];

  const isPremium =
    activeSubscriptions.filter((sub) => sub.game === currentGame).length > 0;

  const isAdmin = user?.role === 'ADMIN';

  const isCoach = user?.role === 'COACH';

  return { user, isPremium, isAdmin, isCoach, activeSubscriptions };
};
