import React, { lazy } from 'react';

const Maintenance = lazy(() => import('../screens/Maintenance'));

const addCommonProps = (route) => ({
  ...route,
  exact: true,
});

const performerRoutes = [
  {
    path: '/maintenance',
    element: () => <Maintenance />,
  },
];

const mappedClassRoutes = performerRoutes.map(addCommonProps);

export default mappedClassRoutes;
