import React from 'react';

import Avatar from '@mui/material/Avatar';
import useUserSelector from '@src/store/selectors/useUserSelector';
import { Color } from '@styles/variables';

import AvatarIcon from '../../assets/svgs/AvatarIcon.svg';

const UserLogo = ({ onClick, classes, size = 40 }) => {
  const { user } = useUserSelector();
  const avatarIcon = user?.profile?.image || AvatarIcon;

  return (
    <Avatar
      className={classes}
      onClick={onClick}
      alt="user"
      src={avatarIcon}
      style={{
        background: Color.gray[1],
        cursor: onClick ? 'pointer' : 'auto',
        width: size,
        height: size,
      }}
    />
  );
};

export default UserLogo;
