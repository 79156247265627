const izziApiUrl = process.env.REACT_APP_IZZI_API_URL;
const izziStorageUrl = process.env.REACT_APP_IZZI_STORAGE_URL;
const izziDiscordUrl = process.env.REACT_APP_IZZI_DISCORD_URL;
const youtubeApiUrl = process.env.REACT_APP_YOUTUBE_API_URL;
const youtubeApiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
const vimeoApiUrl = process.env.REACT_APP_VIMEO_API_URL;
const vimeoToken = process.env.REACT_APP_VIMEO_API_TOKEN;
const lolPatchVersion = process.env.REACT_APP_LOL_PATCH;
const lolDataDragonApiUrl = process.env.REACT_APP_LOL_DATA_DRAGON_API_URL;
const lolDataDragonCdnUrl = process.env.REACT_APP_LOL_DATA_DRAGON_CDN_URL;
const pagarmeEncryptionKey = process.env.REACT_APP_PAGARME_ENCRYPTION_KEY;
const mixpanelTokenStaging = process.env.REACT_APP_MIXPANEL_TOKEN_STAGING;
const mixpanelTokenProd = process.env.REACT_APP_MIXPANEL_TOKEN_PROD;
const environment = process.env.NODE_ENV;
const gamesIndicationFormsUrl = process.env.REACT_APP_GAMES_INDICATION_FORMS;
const userTermsUrl = process.env.REACT_APP_USER_TERMS;
const izziAppEnv = process.env.REACT_APP_ENVIRONMENT;
const izziLp = process.env.REACT_APP_LANDING_PAGE;
const izziWhatsappEnv = process.env.REACT_APP_WHATSAPP;
const izziOldUrl = process.env.REACT_APP_IZZI_OLD_URL;
const lolRankingRolesUrl = process.env.REACT_APP_LOL_RANKING_ROLES_URL;
const maintenanceModeConfig =
  process.env.REACT_APP_MAINTENANCE_MODE === 'true' ||
  process.env.REACT_APP_MAINTENANCE_MODE === true;

export const izzi = izziApiUrl;
export const izziOld = izziOldUrl;
export const izziApp = izziAppEnv;
export const izziWhatsapp = izziWhatsappEnv;
export const izziLpUrl = izziLp;
export const izziStorage = izziStorageUrl;
export const izziDiscord = izziDiscordUrl;
export const lolPatch = lolPatchVersion;
export const pagarmeKey = pagarmeEncryptionKey;
export const env = environment;
export const gamesIndicationForms = gamesIndicationFormsUrl;
export const regulation = lolRankingRolesUrl;
export const userTermsLink = userTermsUrl;
export const getStreamFeed = {
  VALORANT: {
    appId: process.env.REACT_APP_STREAM_APP_VALORANT_ID,
    apiKey: process.env.REACT_APP_STREAM_API_VALORANT_KEY,
  },
  LOL: {
    appId: process.env.REACT_APP_STREAM_APP_LOL_ID,
    apiKey: process.env.REACT_APP_STREAM_API_LOL_KEY,
  },
  FORTNITE: {
    appId: process.env.REACT_APP_STREAM_APP_FORTNITE_ID,
    apiKey: process.env.REACT_APP_STREAM_API_FORTNITE_KEY,
  },
};
export const streamMainFeedId = process.env.REACT_APP_STREAM_MAIN_FEED_ID;
export const pageHotmartLol = process.env.REACT_APP_HOTMART_SUBSCRIPTION_LOL;
export const pageHotmartValorant =
  process.env.REACT_APP_HOTMART_SUBSCRIPTION_VALORANT;
export const pageHotmartFortnite =
  process.env.REACT_APP_HOTMART_SUBSCRIPTION_FORTNITE;
export const mixpanelToken =
  env === 'production' && mixpanelTokenProd
    ? mixpanelTokenProd
    : mixpanelTokenStaging;

export const vendors = {
  youtube: {
    url: youtubeApiUrl,
    key: youtubeApiKey,
  },
  vimeo: {
    url: vimeoApiUrl,
    token: vimeoToken,
  },
  lolDataDragonApi: {
    url: lolDataDragonApiUrl,
  },
  lolDataDragonCdn: {
    url: lolDataDragonCdnUrl,
  },
};

export const maintenanceMode = maintenanceModeConfig;
