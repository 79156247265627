import React, { lazy } from 'react';

import DefaultLayout from '../layout/Default';

const MyStudents = lazy(() => import('../screens/MyStudents'));

const addCommonProps = (route) => ({
  ...route,
  exact: true,
  layout: DefaultLayout,
});

const classRoutes = [{ path: '/my-students', element: () => <MyStudents /> }];

const mappedClassRoutes = classRoutes.map(addCommonProps);

export default mappedClassRoutes;
