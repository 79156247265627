import React, { lazy } from 'react';

import DefaultLayout from '../layout/Default';

const Dashboard = lazy(() => import('../screens/Dashboard'));

const addCommonProps = (route) => ({
  ...route,
  exact: true,
  layout: DefaultLayout,
});

const dashboardRoutes = [{ path: '/home', element: () => <Dashboard /> }];

const mappedDashboardRoutes = dashboardRoutes.map(addCommonProps);

export default mappedDashboardRoutes;
