import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useCurrentGame from '@hooks/localStorage/useCurrentGame';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import requester from '@resources/requester';
import useUserSelector from '@src/store/selectors/useUserSelector';
import { FontFamily } from '@styles/variables';
import { getActiveSubscriptions } from '@utils/subscription.utils';
import setToast from '@utils/toast.utils';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import Menu, { Divider, Item as MenuItem } from 'rc-menu';

import logoIzzi from '../../assets/izzi/izzi-icon.png';
import useGlobal from '../../store';
import Sidebar from '../Sidebar/Sidebar';
import SubscribeButton from '../SubscribeButton';
import Text from '../typography/Text.component';
import UserLogo from '../UserLogo';
import SelectGame from './components/SelectGame';
import { SignInButton, SignUpButton } from './style';

const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
    background: '#262626',
    padding: '0px 21px',
    position: 'fixed',
    zIndex: 2,
    display: 'flex',
    minHeight: 54,
    alignItems: 'center',
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },

  logo: {
    width: 40,
    cursor: 'pointer',
  },
  gameInformation: {
    display: 'flex',
    alignItems: 'center',

    marginLeft: 40,
  },
  gameInformationText: {
    fontSize: 14,
    fontFamily: FontFamily.POPPINS,
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#BFBFBF',

    marginLeft: 13,

    '@media (max-width: 695px)': {
      display: 'none',
    },
  },
  containerInformation: {
    display: 'flex',
    alignItems: 'center',
  },
  containerButton: {
    alignItems: 'center',
    display: 'flex',

    '@media (max-width: 695px)': {
      display: 'none',
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    fontSize: 14,
    fontFamily: FontFamily.POPPINS,
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#BFBFBF',
    cursor: 'pointer',
    margin: '0px 10px',
    marginTop: 20,
  },
  dropdownText: {
    fontSize: 14,
    fontFamily: FontFamily.POPPINS,
    fontStyle: 'normal',
    fontWeight: 'normal',
    cursor: 'pointer',
    margin: 5,
  },
  user: {
    width: 40,
    height: 40,
    marginLeft: 40,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  menu: {
    width: 40,

    '@media (min-width: 695px)': {
      display: 'none',
    },
  },
  menuOption: {
    width: '100%',
    height: 3,
    backgroundColor: '#8DFF35',
    margin: '7px 0px',
  },
  clickable: {
    cursor: 'pointer',
  },
  select: {
    background: '#3BE529',
    width: '100%',
    height: 4,
    marginTop: 16,
  },
  notSelected: {
    background: '#26262',
    width: '100%',
    height: 4,
    marginTop: 16,
  },
}));

const ApplicationHeader = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [, globalActions] = useGlobal();
  const [games, setGames] = useState([]);

  const { logOut } = globalActions;
  const { user } = useUserSelector();
  const { currentGame, isLol, isFortnite } = useCurrentGame();
  const activeSubscriptions = getActiveSubscriptions(user);
  const userId = user?._id;

  const hasSubscription =
    activeSubscriptions.filter((sub) => sub.game === currentGame).length > 0;

  const mapGame = (response) => ({
    name: response.name,
    codeName: response.codeName,
    publisher: response.publisher,
    logo: response.logo,
  });

  const fetchGame = async () => {
    const [error, response] = await requester('GET', `/v1/public/games`);

    if (error) {
      setToast('error', 'Erro ao carregar games.');
    } else {
      const { data } = response;
      const mappedGames = data.map(mapGame);
      setGames(mappedGames);
    }
  };

  useEffect(() => {
    fetchGame();
  }, []);

  const path = window.location.pathname;

  const isMenuActive = (slugs) => slugs.some((slug) => path.includes(slug));
  const isMenuActiveClass = (slugs) =>
    isMenuActive(slugs) ? classes.select : classes.notSelected;

  const menu = (
    <Menu style={{ width: 140 }} selectable={false}>
      <MenuItem
        key="1"
        className={classes.clickable}
        onClick={() => navigate('/profile')}
      >
        <Text className={classes.dropdownText}>Meu Perfil</Text>
      </MenuItem>
      <Divider />
      {user && user.role !== 'PLAYER' && isLol && (
        <>
          <MenuItem
            key="2"
            className={classes.clickable}
            selectable={false}
            onClick={() => navigate('/my-students')}
          >
            <Text className={classes.dropdownText}>Meus Alunos</Text>
          </MenuItem>
          <Divider />
        </>
      )}
      <MenuItem
        key="3"
        className={classes.clickable}
        selectable={false}
        onClick={() => logOut(navigate)}
      >
        <Text className={classes.dropdownText}>Sair</Text>
      </MenuItem>
    </Menu>
  );

  const logoRedirect = () => {
    if (user) navigate('/home');
    else navigate('/signin');
  };

  return (
    <>
      <section className={classes.header}>
        <div className={classes.sectionHeader}>
          <div className={classes.containerInformation}>
            <img
              role="presentation"
              src={logoIzzi}
              alt="logo Izzi"
              className={classes.logo}
              onClick={logoRedirect}
            />
            {user && (
              <div className={classes.gameInformation}>
                <SelectGame listGames={games} userId={userId} />
              </div>
            )}
          </div>
          {user ? (
            <Box
              sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}
            >
              <div className={classes.buttons}>
                <Text className={classes.button} component={Link} to="/home">
                  Home
                  <div className={isMenuActiveClass(['home'])} />
                </Text>

                {!isFortnite && (
                  <Text
                    className={classes.button}
                    component={Link}
                    to={`/analysis/${currentGame}/${userId}`}
                  >
                    Evolução
                    <div className={isMenuActiveClass(['analysis'])} />
                  </Text>
                )}

                {isLol && (
                  <Text
                    className={classes.button}
                    component={Link}
                    to="/izzi-build/lol"
                  >
                    Guias
                    <div className={isMenuActiveClass(['izzi-build'])} />
                  </Text>
                )}

                <Text className={classes.button} component={Link} to="/classes">
                  Cursos
                  <div className={isMenuActiveClass(['classes'])} />
                </Text>

                <Text className={classes.button} component={Link} to="/library">
                  Biblioteca
                  <div className={isMenuActiveClass(['library'])} />
                </Text>
                {isLol && (
                  <Text
                    className={classes.button}
                    component={Link}
                    to="/coaches"
                  >
                    Coaches
                    <div className={isMenuActiveClass(['coaches'])} />
                  </Text>
                )}
              </div>
              {!hasSubscription && (
                <SubscribeButton left currentGame={currentGame} />
              )}

              <Dropdown trigger={['click']} overlay={menu} animation="slide-up">
                <UserLogo classes={classes.user} />
              </Dropdown>
            </Box>
          ) : (
            <Box
              sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}
            >
              <SignUpButton onClick={() => navigate('/signup')}>
                Cadastre-se na IZZI
              </SignUpButton>
              <SignInButton type="button" onClick={() => navigate('/signin')}>
                Entrar
              </SignInButton>
            </Box>
          )}
        </div>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Sidebar
            user={user}
            hasSubscription={hasSubscription}
            currentGame={currentGame}
          />
        </Box>
      </section>
    </>
  );
};

export default ApplicationHeader;
