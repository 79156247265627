export const getActiveSubscriptions = (userObj) => {
  const date = new Date();

  const subscriptions =
    userObj?.subscription?.filter(
      (s) => s.status === 'ACTIVE' && date <= new Date(s.expiresIn)
    ) ?? [];

  return subscriptions;
};

export const getMainGame = (subscriptions) => {
  if (!subscriptions || subscriptions?.length === 0) {
    return 'LOL';
  }
  return subscriptions[0].game;
};

export const modalDescriptionLOL = [
  {
    id: 0,
    description: 'Cursos Completos de Todas as Lanes com Campeões do CBLOL',
  },
  {
    id: 1,
    description: 'Sessão Individual com Coach Grão Mestre toda Semana',
  },
  {
    id: 2,
    description: 'Canal Exclusivo com Coach 24h no Discord',
  },
  {
    id: 3,
    description: 'Campeonatos e Rankings Exclusivos com Premiação',
  },
];

export const modalDescriptionVALORANT = [
  {
    id: 0,
    description: 'Curso Completo com Fundamentos e Guias de Mapas',
  },
  {
    id: 1,
    description: 'Sessão Individual com Coach Imortal toda Semana',
  },
  {
    id: 2,
    description: 'Canal Exclusivo com Coach 24h no Discord',
  },
  {
    id: 3,
    description: 'Campeonatos e Rankings Exclusivos com Premiação',
  },
];
