import React, { lazy } from 'react';

const IzziBuild = lazy(() => import('../screens/ChampionsBuild'));
const Build = lazy(() => import('../screens/Build'));

const addCommonProps = (route) => ({
  ...route,
  exact: true,
});

const buildRoutes = [
  {
    path: '/izzi-build/:game',
    element: () => <IzziBuild />,
  },
  {
    path: '/izzi-build/:game/:championName',
    element: () => <Build />,
  },
];

const mappedClassRoutes = buildRoutes.map(addCommonProps);

export default mappedClassRoutes;
