import React, { lazy } from 'react';

const AnalysisResult = lazy(() =>
  import('../screens/Analysis/AnalysisResult.screen')
);
const ValorantAnalysis = lazy(() =>
  import('../screens/Analysis/valorant/ValorantAnalysis.screen')
);

const addCommonProps = (route) => ({
  ...route,
  exact: true,
});

const analysisRoutes = [
  {
    path: '/analysis/lol/:userId',
    element: () => <AnalysisResult />,
  },
  {
    path: '/analysis/valorant/:userId',
    element: () => <ValorantAnalysis />,
  },
];

const mappedClassRoutes = analysisRoutes.map(addCommonProps);

export default mappedClassRoutes;
