import React, { lazy } from 'react';

const PerformerResult = lazy(() => import('../screens/Performer'));

const addCommonProps = (route) => ({
  ...route,
  exact: true,
});

const performerRoutes = [
  {
    path: '/performer/:performerId',
    element: () => <PerformerResult />,
  },
];

const mappedClassRoutes = performerRoutes.map(addCommonProps);

export default mappedClassRoutes;
