import React, { lazy } from 'react';

const RankingResult = lazy(() => import('../screens/Ranking'));

const addCommonProps = (route) => ({
  ...route,
  exact: true,
});

const rankingRoutes = [
  {
    path: '/ranking/:game',
    element: () => <RankingResult />,
  },
];

const mappedClassRoutes = rankingRoutes.map(addCommonProps);

export default mappedClassRoutes;
