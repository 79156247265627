import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useNavigate } from 'react-router-dom';

import OutlinedButton from '@components/Button/OutlinedButton';
import useCurrentGame from '@hooks/localStorage/useCurrentGame';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosSharp from '@material-ui/icons/ArrowForwardIosSharp';
import Box from '@mui/material/Box';
import { izziDiscord } from '@resources/config';
import { Color } from '@styles/variables';

import IconLol from '../../assets/icons/Lol.svg';
import ValorantLogo from '../../assets/images/games/valorant/logo.png';
import logoIzzi from '../../assets/izzi/izzi-icon.png';
import GreenDiscord from '../../assets/svgs/green-discord.svg';
import useGlobal from '../../store';
import SubscribeButton from '../SubscribeButton';
import UserLogo from '../UserLogo';
import './Sidebar.css';

const useStyles = makeStyles(() => ({
  userIcon: {
    marginTop: '16px',
    marginBottom: '14px',
  },
  logo: {
    width: 40,
    cursor: 'pointer',
  },
  menuItem: {
    fontSize: '20px',
  },
  gameNameContainer: {
    borderBottom: `1px solid ${Color.gray[8]}`,
    width: '100%',
    paddingBottom: '12px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginBottom: '56px',
    '&:focus': {
      outline: '2px solid transparent',
      outlineOffset: 2,
    },
  },
  gameLabel: {
    fontSize: '14px',
    color: Color.gray[7],
    marginBottom: '6px',
  },
  arrowIcon: {
    marginLeft: '16px',
  },
  currentGame: {
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  gameIcon: {
    width: '26px',
    height: '26px',
  },
}));

const menuStyles = {
  bmBurgerBars: {
    background: Color.gray[1],
  },
  bmBurgerBarsHover: {
    opacity: 0.7,
  },
  bmCross: {
    background: Color.gray[1],
  },
  bmCrossButton: {
    marginTop: '8px',
    marginRight: '8px',
  },
  bmItemList: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  bmItem: {
    marginTop: '0px',
    marginBottom: '30px',
    fontSize: '20px',
  },
  bmMenu: {
    backgroundColor: Color.gray[9],
  },
  bmMenuWrap: {
    width: '80%',
    top: 0,
  },
  bmOverlay: {
    backgroundColor: Color.gray[8],
    opacity: 0.2,
    top: 1,
    bottom: 1,
    left: 1,
    right: 1,
  },
};

const toggleMenu = ({ isOpen }) => {
  const menuWrap = document.querySelector('.bm-menu-wrap');
  if (isOpen) {
    menuWrap.setAttribute('aria-hidden', false);
  } else {
    menuWrap.setAttribute('aria-hidden', true);
  }
};

const Sidebar = ({ user, hasSubscription, currentGame }) => {
  const classes = useStyles();
  const { isLol, isValorant } = useCurrentGame();
  const [, globalActions] = useGlobal();
  const navigate = useNavigate();

  const { logOut } = globalActions;

  const handleCoachesClickMenuItem = (e) => {
    e.preventDefault();
    navigate('/coaches');
  };

  const handleClickLogout = (e) => {
    e.preventDefault();
    logOut(navigate);
  };

  const renderCurrentGame = () => (
    <Box className={classes.currentGame}>
      {isLol && (
        <>
          <img className={classes.gameIcon} src={IconLol} alt="logo do jogo" />
          League of Legends
        </>
      )}
      {isValorant && (
        <>
          <img
            className={classes.gameIcon}
            src={ValorantLogo}
            alt="logo do jogo"
          />
          Valorant
        </>
      )}
    </Box>
  );

  const renderMenuItem = (label, href, onClick) => (
    <a
      className={classes.menuItem}
      href={href}
      onClick={onClick}
      style={user ? {} : { fontSize: 16 }}
    >
      {label} <ArrowForwardIosSharp className={classes.arrowIcon} />
    </a>
  );

  return (
    <>
      {user ? (
        <Menu styles={menuStyles} onStateChange={toggleMenu} right>
          <UserLogo classes={classes.userIcon} size={50} />

          <Box
            className={classes.gameNameContainer}
            style={{ display: 'flex' }}
          >
            <Box className={classes.gameLabel}>Jogo</Box>
            {renderCurrentGame()}
          </Box>

          {!hasSubscription && (
            <SubscribeButton margin currentGame={currentGame} />
          )}

          {renderMenuItem('Home', '/home')}
          {renderMenuItem('Evolução', '/analysis')}
          {isLol && renderMenuItem('Guias', '/izzi-build/lol')}
          {renderMenuItem('Cursos', '/classes')}
          {renderMenuItem('Biblioteca', '/library')}
          {isLol && renderMenuItem('Coaches', '/', handleCoachesClickMenuItem)}
          {user &&
            user.role !== 'PLAYER' &&
            isLol &&
            renderMenuItem('Meus Alunos', '/my-students')}
          {renderMenuItem('Meu Perfil', '/profile')}
          {renderMenuItem('Sair', '/', handleClickLogout)}

          <OutlinedButton
            styles={{ padding: '11px 16px', marginTop: 16 }}
            iconStart={<img src={GreenDiscord} alt="discord" />}
            onClick={() => {
              window.open(izziDiscord, '_blank');
            }}
            title="Acesse nosso Discord"
          />
        </Menu>
      ) : (
        <Menu styles={menuStyles} onStateChange={toggleMenu} right>
          <img
            role="presentation"
            src={logoIzzi}
            alt="logo Izzi"
            className={classes.logo}
          />
          {renderMenuItem('Cadastre-se na IZZI', '/signup')}
          {renderMenuItem('Entrar', '/signin')}

          <OutlinedButton
            styles={{ padding: '11px 16px', marginTop: 16, fontSize: 16 }}
            iconStart={<img src={GreenDiscord} alt="discord" />}
            onClick={() => {
              window.open(izziDiscord, '_blank');
            }}
            title="Acesse nosso Discord"
          />
        </Menu>
      )}
    </>
  );
};

export default Sidebar;
