import React, { lazy } from 'react';

const CoachSchedule = lazy(() => import('../screens/CoachSchedule'));

const addCommonProps = (route) => ({
  ...route,
  exact: true,
});

const coachesRoutes = [
  {
    path: '/coaches/:currentGame/:coachName/schedule/:transactionId',
    element: () => <CoachSchedule />,
  },
];

const mappedCoachesRoutes = coachesRoutes.map(addCommonProps);

export default mappedCoachesRoutes;
