import styled from 'styled-components';

import { Color, FontFamily } from '../../styles/variables';

export const SignUpButton = styled.button`
  display: flex;
  width: 170px;
  height: 30px;
  background: ${Color.lime[12]};
  box-shadow: 0px 0px 8px rgba(111, 255, 1, 0.4);
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  text-align: center;
  font-family: ${FontFamily.POPPINS};
  font-size: 14px;
  color: ${Color.gray[9]};
`;

export const SignInButton = styled.div`
  font-size: 14px;
  font-family: ${FontFamily.POPPINS};
  color: ${Color.gray[2]};
  cursor: pointer;
  margin: 0px 10px 0px 20px;
`;
