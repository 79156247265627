import requester from '../resources/requester';
import setToast from '../utils/toast.utils';

export const cleanDiagnostic = (store) => {
  const diagnostic = {};
  store.setState({ ...store.state, diagnostic });
};

export const selectDiagnostic = async (store, diagnostic) => {
  store.setState({ ...store.state, diagnostic });
};

export const sendDiagnosticRequest = async (
  store,
  navigate,
  diagnosticData
) => {
  const [error, response] = await requester(
    'POST',
    '/v1/public/diagnostics',
    diagnosticData
  );
  if (error || ![200, 201].includes(response.status)) {
    setToast(
      'error',
      'Tivemos um problema com o envio dos seus dados, por favor tente novamente!'
    );
  } else {
    const diagnostic = {
      ...store.state.diagnostic,
      userNickname: diagnosticData.userNickname,
    };
    store.setState({ ...store.state, diagnostic });
    navigate('/diagnostic/result');
  }
};

export const setSalesButtonClicked = async (store, diagnosticId) => {
  await requester('PATCH', `/v1/public/diagnostics/${diagnosticId}`, {
    salesButtonClicked: true,
  });
};

export const getDiagnostic = async (store, navigate, diagnosticId) => {
  const [error, response] = await requester(
    'GET',
    `/v1/public/diagnostics/${diagnosticId}`
  );
  if (error) {
    setToast('error', 'setDiagnosticError');
  } else {
    selectDiagnostic(store, response.data);
  }
};
