import React from 'react';

import useHotmart from '@src/hooks/useHotmart';
import Mixpanel from '@utils/mixpanel.utils';

import { Container, Title } from './style';

const SubscribeButton = ({ margin, left, currentGame }) => {
  const { redirectToHotmart } = useHotmart();

  const clickButton = () => {
    Mixpanel().track(`Click Subscription Menu`, {
      game: currentGame,
    });

    redirectToHotmart();
  };
  return (
    <Container margin={margin} left={left} onClick={() => clickButton()}>
      <Title>Seja Assinante</Title>
    </Container>
  );
};

export default SubscribeButton;
