import styled from 'styled-components';

import { Color, FontFamily } from '../../styles/variables';

export const Container = styled.button`
  background: ${Color.lime[12]};
  box-shadow: 0px 0px 8px rgba(111, 255, 1, 0.4);
  border-radius: 4px;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 0;
  cursor: pointer;
  width: 100%;
  margin-bottom: ${(props) => (props.margin ? '20px' : '0px')};
  margin-left: ${(props) => (props.left ? '20px' : '0px')};
`;

export const Title = styled.h1`
  font-family: ${FontFamily.POPPINS};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;
