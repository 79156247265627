import React, { lazy } from 'react';

import DefaultLayout from '../layout/Default';

const Coaches = lazy(() => import('../screens/Coaches'));
const Coach = lazy(() => import('../screens/Coach'));

const addCommonProps = (route) => ({
  ...route,
  exact: true,
  layout: DefaultLayout,
});

const coachesRoutes = [
  { path: '/coaches', element: () => <Coaches /> },
  {
    path: '/coaches/:currentGame/:displayName',
    element: () => <Coach />,
  },
];

const mappedCoachesRoutes = coachesRoutes.map(addCommonProps);

export default mappedCoachesRoutes;
