const common = {
  agree: 'Ok, entendi',
  all: 'Todos',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  delete: 'Excluir',
  description: 'Descrição',
  finalized: 'Finalizados',
  newGame: 'Novo jogo',
  ok: 'Ok',
  pending: 'Pendentes',
  register: 'Cadastrar',
  reviewed: 'Analizados',
  save: 'Salvar',
  update: 'Atualizar',
};

export const ptBR = {
  dialogs: {
    alert: {
      deleteComment: {
        title: 'Excluir comentário!',
        description:
          'Deseja excluir esse comentário?\nEssa alteração não poderá ser desfeita!',
      },
      deleteContent: {
        title: 'Excluir conteúdo!',
        description:
          'Deseja excluir o conteúdo {{key1}}?\nEssa alteração não poderá ser desfeita!',
      },
      deleteMarker: {
        title: 'Excluir marcação!',
        description:
          'Deseja excluir a marcação {{key1}}?\nEssa alteração não poderá ser desfeita!',
      },
      deleteUser: {
        title: 'Excluir usuário!',
        description:
          'Deseja excluir o usuário {{key1}}?\nEssa alteração não poderá ser desfeita!',
      },
      discord: {
        title: 'Erro ao conectar com o Discord!',
        description:
          'Não foi possível conectar sua conta ao Discord.\nTente novamente!',
      },
      forgotPassword: {
        title: 'Esqueceu sua senha!',
        description:
          'Enviamos instruções de como redefinir a sua senha para {{key1}}, verifique sua caixa de mensagens!',
      },
      forgotPasswordError: {
        title: 'Erro ao solicitar nova senha! ',
        description:
          'Não foi possível validar seu usuário/senha.\nVerifique seus dados e tente novamente!',
      },
      generic: {
        title: 'Oops!',
        description: 'Algo deu errado. Tente novamente!',
      },
      genericDecision: {
        title: 'Atenção!',
        description:
          'Essa alteração não poderá ser desfeita.\nDeseja continuar?',
      },
      signIn: {
        title: 'Erro ao logar!',
        description:
          'Não foi possível validar seu usuário/senha.\nVerifique seus dados e tente novamente!',
      },
      lol: {
        title: 'Erro ao conectar com o LoL!',
        description:
          'Não foi possível buscar os dados do LoL.\nTente novamente!',
      },
      paymentConfirmationError: {
        title: 'Erro ao confirmar pagamento!',
        description:
          'Não conseguimos confirmar o pagamento pelo nosso sistema.\nEntre em contato com nosso suporte!',
      },
      register: {
        title: 'Erro ao cadastrar!',
        description:
          'Não foi possível validar seu cadastro.\nVerifique seus dados e tente novamente!',
      },
      registerProfile: {
        title: 'Erro ao cadastrar seu perfil de jogador!',
        description:
          'Não foi possível validar suas informações de jogador.\nVerifique seus dados e tente novamente!',
      },
      resetPassword: {
        title: 'Resetar senha de usuário!',
        description:
          'Deseja resetar a senha de {{key1}}?\nUma nova senha será enviada para {{key2}}!',
      },
      signInToAccess: {
        title: 'Login necessário!',
        description:
          'Você precisa estar logado para acessar esse conteúdo.\nAcesse a plataforma para visualizá-lo!',
      },
      videoInfo: {
        title: 'Erro ao buscar vídeo!',
        description:
          'Não foi possível buscar as informações do vídeo.\nTente novamente!',
      },
    },
    info: {
      boughtSession: {
        title: 'Compra de sessão aprovada!',
        description:
          'Seu/sua coach entrará em contato em até 24h para marcar o horário da sessão!\nEm caso de dúvidas, mande uma mensagem no canal #coaching no nosso discord!',
      },
      boughtCoachSub: {
        title: 'Compra aprovada!',
        description:
          'Sua compra foi aprovada com sucesso!\nEm caso de dúvidas, mande uma mensagem no canal #coaching no nosso discord!',
      },
      pendingPayment: {
        title: 'Pagamento pendente!',
        description:
          'Assim que o pagamento for validado enviaremos um email confirmando a compra!\nEm caso de dúvidas, mande uma mensagem no canal #coaching no nosso discord!',
      },
      pendingSubscription: {
        title: 'Pagamento pendente!',
        description:
          'Assim que o pagamento for validado enviaremos um email confirmando a assinatura!\nEm caso de dúvidas, mande uma mensagem no canal #coaching no nosso discord!',
      },
      proSubscriber: {
        title: 'Conteúdo Premium!',
        description:
          'Você ainda não tem acesso a esse conteúdo!\nEntre em contato pelo discord!',
      },
      scheduleSession: {
        title: 'Sessões individuais de coaching',
        description:
          'Deseja marcar ou adquirir sua sessão?\nMande uma mensagem no canal #coaching no nosso discord!',
      },
      newSubscriber: {
        title: 'Assinatura efetuada com sucesso',
        description:
          'Sua assinatura foi realizada com sucesso.\nAgora você já pode desfrutar de conteúdos exclusivos!!\nEm caso de dúvidas entre em contato com:\n@seller',
      },
      cancelPlan: {
        title: 'Cancelar assinatura',
        description:
          'Ao cancelar a assinatura você não será mais cobrado posteriormente e perderá o acesso aos conteúdos exclusivos após o dia:\n@expiresIn.\nTem certeza que deseja deixar de ter acesso aos conteúdos exclusivos desse plano?',
      },
      cancelPlanConfirm: {
        title: 'Assinatura cancelada',
        description:
          'Sua assinatura foi cancelada e você não será mais cobrado(a) por esse plano!',
      },
    },
  },
  buttons: {
    access: 'Acessar',
    addMarker: 'Adicionar marcação',
    agree: common.agree,
    back: 'voltar',
    cancel: common.cancel,
    changePassword: 'Alterar senha',
    connected: 'Conectado',
    confirm: common.confirm,
    copyPixKey: 'Copiar chave PIX',
    delete: common.delete,
    edit: 'Editar',
    forgotPassword: 'Esqueceu sua senha?',
    goBack: 'Voltar',
    goToDashboard: 'Ir para o dashboard',
    goToDiscord: 'Ir para o Discord',
    iamReady: 'Estou pronto',
    last: 'Últimas',
    learnWithPro: 'Aprenda com um pro',
    monthly: 'Mensal',
    newContent: 'Novo conteúdo',
    ok: common.ok,
    registerOnApp: 'Efetuar cadastro',
    registerInformations: 'Registrar Informações',
    save: common.save,
    sendVerificationEmail: 'Enviar e-mail de verificação',
    signIn: 'Logar',
    signUp: 'Cadastrar',
    start: 'Começar',
    update: common.update,
    userTerms: 'Termos de uso e política de privacidade',
    continue: 'Continuar',
    signPlan: 'Assinar Plano',
    buy: 'Comprar',
    sendData: 'Enviar dados',
  },
  informations: {
    discordId:
      'Digite o seu Discord e seu Discord ID separados de uma #. Exemplo Joao#1021',
  },
  labels: {
    accessIZZIDiscord: 'Acessar o discord da IZZI',
    actions: 'Ações',
    addComment: 'Adicione um comentário...',
    additionalInfo: 'Informações adicionais',
    admin: 'Administrador',
    adminUsers: 'Administrador / Usuários',
    all: 'Todos',
    allf: 'Todas',
    allDone: 'Tudo certo!',
    analysis: 'Análises',
    areYouPrepared: 'Você está preparado?',
    as: 'como',
    champion: 'Champion',
    champions: 'Champions',
    changeSubscription: 'Trocar assinatura',
    clickToConnect: 'Clique para conectar',
    coach: 'Coach',
    coaches: 'Coaches',
    coachPresentation: 'Apresentação do coach',
    coachProfile: 'Perfil do coach',
    comments: 'Comentários',
    confirmPassword: 'Confirmar senha',
    contents: 'Conteúdos',
    courses: 'Cursos',
    createContent: 'Criar conteúdo',
    createMarker: 'Criar marcação',
    currentDifficulties: 'Dificuldades atuais',
    currentLevel: 'Elo Atual',
    currentSeason: 'Temporada atual',
    dashboard: 'Dashboard',
    deleteUser: 'Excluir usuário',
    desiredRank: 'Elo desejado',
    discord: 'Discord',
    doubts: 'Dúvidas',
    editContent: 'Editar conteúdo',
    email: 'E-mail',
    evolution: 'Evolução',
    expiresIn: 'Expira em',
    expiredIn: 'Expirou em',
    favoriteLanes: 'Lanes favoritas',
    flags: 'Flags',
    free: 'Grátis',
    howKnowUs: 'Como conheceu a IZZI?',
    hour: 'hora',
    hours: 'horas',
    knowIZZICoaches: 'Conhecer os coaches da IZZI',
    lastMatches: 'Últimas partidas (Ranked Solo/Duo)',
    lanes: 'Lanes',
    learnWithPro: 'Aprenda com um profissional',
    logOut: 'Sair',
    lose: 'Derrota',
    markers: 'Marcações',
    matchToFirstAnalysis: 'Partida para a análise inicial',
    matchup: 'Matchup',
    moreGamesCommingSoon: 'Mais jogos em breve...',
    myPlayers: 'Meus alunos',
    name: 'Nome',
    never: 'Nunca',
    noAdditionalDifficulty: 'Nenhuma dificuldade adicional',
    noCommentsToShow:
      'Essa marcação ainda não possui comentários, que tal comentar?',
    noIZZIContentToShow: 'Em breve lançaremos conteúdos exclusivos!',
    noMatchesToShow: 'Nenhuma partida rankeada para ser exibida...',
    noMarkerSelected: 'Nenhuma marcação selecionada!',
    noMarkersToShow: 'Esse vídeo ainda não possui marcações, que tal começar?',
    noPartnerContentToShow: 'Nenhuma conteúdo da academia para ser exibido...',
    noPlayers: 'Nenhum aluno vinculado...',
    noPublicContentToShow: 'Nenhum conteúdo público para ser exibido...',
    otherDifficulties: 'Outras dificuldades',
    overview: 'Visão geral',
    password: 'Senha',
    partnerProfile: 'Perfil da academia',
    notFound: 'Página não encontrada',
    partner: 'Academia',
    partners: 'Academias',
    patch: 'Patch',
    plan: 'Plano',
    plansValues: 'Valores dos planos',
    players: 'Jogadores',
    placementsStartedToBeMonitored: 'Sua evolução começou a ser monitorada!',
    placementsChartData: 'O gráfico será preechido diariamente com seus PDL',
    pixQRCodePayment: 'Utilize o QRCode para realizar o pagamento:',
    profile: 'Perfil',
    private: 'Privado',
    public: 'Público',
    publics: 'Públicos',
    rank: 'Rank',
    recommendedBy: 'Recomendado por',
    resetPassword: 'Resetar password',
    riotApiUsage:
      'A IZZI Gaming não é endossada pela Riot Games e não reflete as visões ou opiniões da Riot Games ou de qualquer pessoa oficialmente envolvida na produção ou gerenciamento de propriedades da Riot Games. Riot Games e todas as propriedades associadas são marcas comerciais ou marcas registradas da Riot Games, Inc.',
    role: 'Tipo',
    searchBy: 'Pesquise por título',
    security: 'Segurança',
    session: 'Sessão',
    sessions: 'Sessões',
    sessionsMade: 'Sessões realizadas',
    sessionsMadeKey: '{{key}} sessões realizadas',
    sessionValues: 'Valores das sessões',
    signOut: 'Sign Out',
    startingAt: 'A partir de',
    subscription: 'Assinatura',
    summoner: 'Nome de invocador no LoL',
    tier: 'Tier',
    time: 'Tempo',
    title: 'Título',
    toCommentSelectMarker: 'Para comentar selecione um marcador!',
    type: 'Tipo',
    unauthorized: 'Acesso negado',
    username: 'Nome de usuário',
    usernameIzzi: 'Nome de usuário na IZZI',
    users: 'Usuários',
    verifiedAccount: 'Conta verificada!',
    verifiedAccountDescription:
      'Acesse a plataforma e aproveite toda a experiência IZZI!',
    videoLibrary: 'Biblioteca de vídeos',
    videoUrl: 'Vídeo url',
    unverifiedAccount: 'Conta não verificada!',
    unverifiedAccountDescription:
      'Não foi possível verificar sua conta! Realize uma nova solicitação de validação no seu perfil!',
    you: 'Você',
    youLearningWithPro: 'Você aprendendo com um profissional',
    welcome: 'Bem-vindo!',
    watchContentInVideoLibrary:
      'Assistir a um conteúdo na biblioteca de vídeos',
    whatDoYouNeedToDoToday: 'O que voce precisa fazer hoje?',
    win: 'Vitória',
    skipStep: 'Pular esta etapa',
    trackTitle: 'Trilha de conteúdos',
    chapters: 'Seções da Trilha',
    content: 'Conteúdo',
    chapter: 'Seção',
    tracks: 'Trilhas',
    subscriber: 'Assinatura',
    subscriberData: 'Dados do assinante',
    paymentMethod: 'Método de pagamento',
    subscriberAddress: 'Endereço do assinante',
    cardData: 'Dados do cartão de crédito',
    creditCardName: 'Nome no cartão de crédito',
    creditCardNumber: 'Número do cartão (XXXX XXXX XXXX XXXX)',
    creditCardExpiration: 'Expiração do cartão (XX/XX)',
    creditCardCvv: 'Código CVV do cartão (XXX)',
    customerCpf: 'CPF/CNPJ',
    customerAddress: 'Endereço de cobrança do assinante',
    customerPhone: 'Número de contato do assinante',
    summarySubscription: 'Resumo da Assinatura',
    price: 'Valor',
    days: 'dias',
    recurrence: 'Recorrência',
    daysText: 'A cada @ dias',
    customerDdd: 'DDD (XXX)',
    streetNumber: 'Número',
    street: 'Endereço',
    neighborhood: 'Bairro',
    zipcode: 'CEP',
    mySubscriptions: 'Minhas assinaturas',
    cancelPlan: 'Cancelar Plano',
    renewPlan: 'Renovar Assinatura',
    canceled: 'Cancelado',
    cancel: 'Cancelar',
    noCancel: 'Não Cancelar',
    seller: 'Vendedor',
    support: 'Suporte',
    summonerName: 'Nome do invocador',
    whatGoalsWithCoach: 'Quais os seus objetivos com o Coach?',
    whatBestTime: 'Qual o melhor período para a sessão?',
    whatUserDiscord: 'Qual o seu usuário no Discord?',
    planPrice: '{{key}}/mês',
    payment: 'Pagamento',
  },
  placeholders: {
    discordUser: 'Insira o seu usuário no Discord',
    seletOneOrMany: 'Selecione um ou mais',
    goalsWithCoach: 'Insira os seus objetivos com o Coach',
  },
  tooltips: {
    pixCodeCopied: 'Chave PIX Copiada!',
  },
  validators: {
    email: 'E-mail inválido!',
    nickanme: 'Nome de invocador precisa ter no mínimo 3 caracteres!',
    password: 'A senha precisa ter no mínimo 6 caracteres!',
    passwordsDontMatch: 'As senhas não coincidem!',
    cpf: 'CPF ou CNPJ inválido',
    required: 'Campo obrigatório',
    discord: {
      invalidDiscordId: 'Usuário Discord inválido. Exemplo João#0267',
    },
    valorant: {
      invalidGamertag: 'Gamertag inválida. Exemplo João#BR1',
    },
  },
  screens: {
    signIn: {
      dontHaveAnAccount: 'Não possui uma conta?',
      signupFree: 'Cadastre-se grátis',
      signin: 'Logar',
      title:
        'Evolua sua Gameplay com ajuda da tecnologia e dos melhores players do Brasil',
      titleLol: 'Faça seu Cadastro e Peça seu Diagnóstico',
      titleValorant:
        'Cadastre-se Grátis e receba uma Análise das suas últimas Partidas Ranqueadas',
      captionSignin: 'Entre na plataforma',
      email: 'Email',
      password: 'Senha',
      placeholderPassword: 'Insira sua senha',
      placeholderEmail: 'Insira seu email',
    },
    gaming: {
      lol: {
        summoner: {
          label: 'Nome de Invocador *',
          placeHolder: 'Insira seu nome de invocador',
        },
        desiredRank: {
          label: 'Meu Objetivo na Season',
          placeHolder: 'Selecione um ELO de objetivo',
        },
        currentDifficulties: {
          label: 'Minhas principais dificuldades',
          placeHolder: 'Selecione as dificuldades',
        },
        mainLane: {
          label: 'Minha lane principal',
          placeHolder: 'Selecione a lane principal',
        },
        izziGoal: {
          label: 'Meu objetivo na IZZI',
          placeHolder: 'Conte-nos os seus objetivos com a IZZI',
        },
      },
      goalsTitle: 'Definições de objetivo',
      title: 'Nos conte o que você deseja evoluir',
      valorant: {
        gameTag: {
          label: 'Gamertag *',
          information:
            'Digite o seu RIOT ID e sua TAG separados de uma #. Exemplo Joao#BR1',
          placeHolder: 'Insira a sua gamerTag (nome#tag)',
        },
        desiredRank: {
          label: 'Meu ranking desejado nesse Episódio/Ato',
          placeHolder: 'Selecione um ranking',
        },
        currentDifficulties: {
          label: 'Minhas dificuldades',
          placeHolder: 'Selecione as dificuldades',
        },
        izziGoal: {
          label: 'Meu objetivo na IZZI',
          placeHolder: 'Conte-nos os seus objetivos com a IZZI',
        },
      },
      fortnite: {
        epicName: {
          label: 'Nome de usuário na Epic',
          placeHolder: 'Insira o seu nome de usuário',
        },
        currentDifficulties: {
          label: 'Minhas dificuldades',
          placeHolder: 'Selecione as dificuldades',
        },
        izziGoal: {
          label: 'Meu objetivo na Hero Base Academy',
          placeHolder: 'Conte-nos os seus objetivos Hero Base Academy',
        },
      },
    },
    register: {
      defineYourPath:
        'Escreva seu próprio caminho de vitórias junto com a IZZI!',
      discord:
        'Conecte seu discord no botão abaixo para receber as notificações da IZZI!',
      firstStep:
        'O primeiro passo é configurar seu perfil de player para garantir a melhor experiência na sua evolução.',
      typeTheSummoner:
        'Escreva abaixo seu nome de invocador no League of Legends para identificarmos seu perfil e criarmos sua experiênica personalizada na IZZI',
      otherGamesIndication:
        'Por enquanto, a IZZI tem suporte apenas para o LoL. Você é de outro jogo? Clique no link abaixo para sugerir os próximos games que entrarão na plataforma.',
      newGameIndication: 'Quero sugerir novos jogos para a IZZI!',
      greetings: 'A IZZI te deseja uma experência única de muita evolução!',
      knowUsBy: 'Conte como nos encontrou e se foi recomendado por alguém!',
      journeyBegins: 'Sua jornada começa agora!',
      success:
        'Seu perfil foi configurado com sucesso e você já pode utilizar todos os recursos da plataforma!',
    },
    signUp: {
      haveAnAccount: 'Já possui uma conta?',
      user: 'Usuário',
      captionSignup: 'Cadastre-se com',
      signin: 'Entrar',
      buttonSignup: 'Cadastrar na IZZI',
      placeholderEmail: 'Digite o seu email',
      placeholderPassword: 'Crie a sua senha',
      placeholderUserName: 'Escolha seu nome de usuário',
      placeholderIzziObjective: 'O que você espera encontrar aqui?',
      izziObjective: 'Meu objetivo com a IZZI é:',
      signupTerms: 'Ao cadastrar, concordo que li e aceito os',
      signupTermsLink:
        'termos de uso e políticas de privacidade da plataforma.',
    },
    tracks: {
      tracks: 'Trilhas',
    },
  },
  toast: {
    createComment: 'Comentário criado!',
    createCommentError: 'Erro ao criar comentário!',
    createContent: 'Conteúdo criado!',
    createContentError: 'Erro ao criar conteúdo!',
    createMarker: 'Marcação criada!',
    createMarkerError: 'Erro ao criar marcação!',
    deleteComment: 'Comentário excluído!',
    deleteCommentError: 'Erro ao excluir comentário!',
    deleteContent: 'Conteúdo excluído!',
    deleteContentError: 'Erro ao excluir conteúdo!',
    deleteMarker: 'Marcação excluída!',
    deleteMarkerError: 'Erro ao excluir marcação!',
    deleteUsers: 'Usuário excluído!',
    deleteUsersError: 'Erro ao excluir usuário!',
    getContentsError: 'Erro ao buscar conteúdos',
    getLolDataApiError: 'Erro ao buscar dados do LoL na DataDragon!',
    getLolHistoryApiError: 'Erro ao buscar histórico do LoL na Riot!',
    getLolMatchesApiError: 'Erro ao buscar partidas!',
    getLolMatchesWinsApiError: 'Erro ao buscar vitórias das partidas!',
    getLolPlacementsApiError: 'Erro ao buscar histórico de evolução!',
    getMarkersError: 'Erro ao buscar marcações!',
    getPartnersError: 'Erro ao carregar academias parceiras!',
    getUsersError: 'Erro ao carregar usuários!',
    getTrackError: 'Erro ao carregar trilhas!',
    getTracksError: 'Erro ao carregar trilha!',
    getTrackChaptersError: 'Erro ao carregar trilha!',
    genericError: 'Algo deu errado. Tente novamente!',
    passwordUpdated: 'Senha atualizada!',
    passwordUpdatedError: 'Erro ao atualizar senha!',
    resetUsersPassword: 'Senha do usuário resetada!',
    resetUsersPasswordError: 'Erro ao resetar senha do usuário!',
    setContentVisibility: 'Visibilidade do conteúdo atualizada!',
    setContentVisibilityError: 'Erro ao atualizar visibilidade do conteúdo!',
    setUsersSubscription: 'Assinatura do usuário atualizada!',
    setUsersSubscriptionError: 'Erro ao atualizar assinatura do usuário!',
    setUsersPlayers: 'Players do usuário atualizados!',
    setUsersPlayersError: 'Erro ao atualizar players do usuário!',
    setUsersRole: 'Role do usuário atualizada!',
    setUsersRoleError: 'Erro ao atualizar role do usuário!',
    setDiagnosticError: 'Erro ao buscar o diagnóstico!',
    profileUpdated: 'Dados do perfil atualizados!',
    updateContent: 'Conteúdo atualizado!',
    updateContentError: 'Erro ao atualizar conteúdo!',
    verificationTokenResend: 'E-mail de verificação enviado!',
    verificationTokenResendError: 'Erro ao enviar de verificação!',
  },
  highlight: {
    valorant: {
      headshotPercent: {
        positive: '{value}% é seu acerto na cabeça',
        negative: '{value}% é seu acerto na cabeça',
      },
      damageByRound: {
        positive: 'Você geralmente causa {value} de dano por round',
        negative: 'Você geralmente causa {value} de dano por round',
      },
      winRateAttack: {
        positive: 'Seu Win Rate atacando é de {value}%',
        negative: 'Seu Win Rate atacando é de {value}%',
      },
      map: {
        positive: '{value} é o seu melhor mapa',
        negative: '{value} é o seu pior mapa',
      },
      winRateDefense: {
        positive: 'Seu Win Rate defendendo é de {value}%',
        negative: 'Seu Win Rate defendendo é de {value}%',
      },
    },
  },
};

export default null;
