import React, { lazy } from 'react';

const DiagnosticResult = lazy(() =>
  import('../screens/diagnostic/DiagnosticResult.screen')
);
const DiagnosticForm = lazy(() =>
  import('../screens/diagnostic/DiagnosticForm.screen')
);
const DiagnosticFormSent = lazy(() =>
  import('../screens/diagnostic/DiagnosticFormSent.screen')
);

const addCommonProps = (route) => ({
  ...route,
  exact: true,
});

const diagnosticRoutes = [
  { path: '/diagnostic', element: () => <DiagnosticForm /> },
  { path: '/diagnostic/result', element: () => <DiagnosticFormSent /> },
  {
    path: '/diagnostic/result/:diagnosticId',
    element: () => <DiagnosticResult />,
  },
];

const mappedClassRoutes = diagnosticRoutes.map(addCommonProps);

export default mappedClassRoutes;
