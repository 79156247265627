import requester from '../resources/requester';
import Mixpanel from '../utils/mixpanel.utils';
import { getActiveSubscriptions } from '../utils/subscription.utils';
import setToast from '../utils/toast.utils';
import { setAuthAndUser } from './utils.action';

export const signIn = async (store, navigate, credentials) => {
  const [error, response] = await requester(
    'POST',
    '/v1/public/auth/signin',
    credentials
  );
  if (error) {
    setToast('error', 'Usuário ou senha incorretos!');
  } else {
    const { token, user } = response.data;
    const isSubscriber = getActiveSubscriptions(user)?.length > 0;

    Mixpanel().people.set({
      $username: user.profile.username,
      $email: user.profile.email,
      $subscriber: isSubscriber,
    });
    Mixpanel().identify(user.profile.email);
    Mixpanel().track(`Login`, {
      username: user.profile.username,
      id: user._id,
    });
    setAuthAndUser(store, token, user);
    navigate('/home');
  }
};

export const signUp = async (store, navigate, params, game, ads) => {
  store.actions.cleanStorage();
  const result = await requester('POST', '/v1/public/auth/signup', params);
  const resultData = result[0] || result[1];
  const response = resultData.response ? resultData.response : resultData;

  switch (response.status) {
    case 201:
    case 200: {
      const { token, user } = response.data;
      const isSubscriber = getActiveSubscriptions(user)?.length > 0;

      Mixpanel().people.set({
        $username: user.profile.username,
        $email: user.profile.email,
        $subscriber: isSubscriber,
        $ads: !!ads,
      });

      Mixpanel().identify(user.profile.email);
      Mixpanel().track(`Signup`, {
        username: user.profile.username,
        id: user._id,
      });
      setAuthAndUser(store, token, user);

      if (game) navigate(`/gaming/${game}`);
      else navigate('/gaming');

      break;
    }
    case 400: {
      if (response?.data?.data?.error?.message?.split(' ')?.includes('Email')) {
        setToast('error', 'Email já cadastrado');
      } else if (
        response?.data?.data?.error?.message?.split(' ')?.includes('Username')
      ) {
        setToast('error', 'Nome de usuário já cadastrado');
      } else {
        setToast('error', 'Erro no cadastro');
      }
      break;
    }
    default:
    case 500: {
      setToast('error', 'Erro no cadastro');
      break;
    }
  }
};

export const forgotPassword = async (store, email) => {
  const [error] = await requester('POST', '/v1/public/auth/forgot-password', {
    email,
  });
  if (error) {
    setToast('error', 'Erro interno');
  } else {
    setToast('success', 'Email enviado');
  }
};

export const resetPassword = async (store, navigate, { token, password }) => {
  const resetPasswordPayload = {
    token,
    password,
  };
  const [error] = await requester(
    'POST',
    '/v1/public/auth/reset-password',
    resetPasswordPayload
  );
  if (error) {
    setToast('error', 'Erro interno');
  } else {
    setToast('success', 'passwordUpdated');
    navigate('/signin');
  }
};

export const logOut = (store, navigate) => {
  store.actions.cleanStorage();
  navigate('/signin');
};
