import { version } from '../../../package.json';

export default () => {
  const izziVersion = `izzi-gaming-${version}`;

  const getStoredUserId = () => localStorage.getItem(`${izziVersion}-user-id`);

  const getStoredToken = () => localStorage.getItem(`${izziVersion}-token`);

  const storeUserId = (userId) =>
    localStorage.setItem(`${izziVersion}-user-id`, JSON.stringify(userId));

  const storeTokenId = (tokenId) =>
    localStorage.setItem(`${izziVersion}-token`, JSON.stringify(tokenId));

  const cleanSection = () => {
    localStorage.removeItem(`${izziVersion}-user-id`);
    localStorage.removeItem(`${izziVersion}-token`);
  };

  return {
    getStoredUserId,
    getStoredToken,
    storeUserId,
    storeTokenId,
    cleanSection,
  };
};
